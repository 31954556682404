import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';




export async function getAllLeaveRequests(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getAllLeaveRequests',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function rejectLeaveRequest(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/rejectLeaveRequest',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getLeaveCounts(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getLeaveCounts',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

  export async function getLeaveTypeList(data) {
    try {
      const response = await axios.post(
        APIEndpoint+'/getLeaveTypeList',data,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error;
    }
}

export async function getEmpBasedLeaveType(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getEmpBasedLeaveType',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function addLeaveRequestAdmin(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/addLeaveRequestAdmin',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function deleteLeave(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/deleteLeaveRequest',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}


import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getAllDesignations() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllDesignation',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {

    return [];
  }
}


export async function designationActions(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/designationActions',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
   
    return error;
  }
}



export async function getTeams() {
    try {
      const response = await axios.get(
        APIEndpoint+'/getAllTeamsDetails',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
     
      return [];
    }
  }


  export async function getAllTeamLeaders() {
    try {
      const response = await axios.get(
        APIEndpoint+'/getTeamLeaders',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
    
      return [];
    }
  }

  export async function getAllTeamCoordinator() {
    try {
      const response = await axios.get(
        APIEndpoint+'/getTeamCoordinator',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {

      return [];
    }
  }


  export async function teamActions(data) {
    try {
      const response = await axios.post(
        APIEndpoint+'/teamActions',data,
        { headers: { Authorization: `Bearer ${token}`,'Content-Type': 'multipart/form-data' } }
      );
      return response.data;
    } catch (error) {
    
      return error;
    }
  }

  export async function getEmpStatus() {
    try {
      const response = await axios.get(
        APIEndpoint+'/getEmploymentStatus',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
     
      return [];
    }
  }

  export async function getActiveTeams() {
    try {
      const response = await axios.get(
        APIEndpoint+'/getActiveTeams',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
     
      return [];
    }
  }
import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getAttendanceData(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getAttendanceData',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function editAttendanceTimeLog(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/editEmployeeWorkLog',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getWeekWiseTimeSheet(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getWeekWiseTimeSheet',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getWeekTimeLogData(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getWeekTimeLogData',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}


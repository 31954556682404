import React, {useState, useEffect, useRef} from "react";

import Swal from "sweetalert2";
import {Button, Input, Modal, Table, Tooltip} from "antd";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    getPolicyDocumentList,
    getSpecificPolicies,
    saveDocumentCategories,
    storePolicyDocument
} from "../services/PolicyDocumentAPIServices";
import {adminUrl, documentUrl, websiteUrl} from "../UrlPaths";
import {SearchOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import { PDFReader } from 'reactjs-pdf-reader';

function PolicyCategory() {
    const { id } = useParams();
  const spinner = document.getElementById("spinner");
  const inputRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [document_name, setDocumentName] = useState("");
  const [status, setStatus] = useState("1");
  const [categoryId, setCategoryId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Document Category");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [document_path, setDocument] = useState("");
  const [existedImage, setExistedImage] = useState("");
  const [imageLabel, setImageLabel] = useState("");
  const [category_name, setCategoryName] = useState("");
  const [description, setDescription] = useState("");

    const columns = [
        {
            key: "category_name",
            title: "Document",
            filterMode: "tree",
            width:'25%',
            filterSearch: true,
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            <div style={{cursor:'pointer'}}
                                 onClick={() =>
                                     popupDocument(
                                         `${adminUrl}${record.document}`
                                     )
                                 }>
                                <img
                                    src={`${websiteUrl}app-assets/img/pdf-format.png`}
                                    className="mr-2"
                                    alt="pdf image"
                                    width="30"
                                    height="30"
                                />
                                <span className="text-bold-500"> {record.category_name}</span>
                            </div>

                        </div>
                    </>
                );
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <>
                        <Input
                            autoFocus
                            placeholder="Type category"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        ></Input>
                        <Button
                            onClick={() => {
                                confirm();
                            }}
                            type="primary"
                        >
                            Search
                        </Button>
                    </>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => {
                return record.category_name
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
        },
        {
            key: "description",
            title: "Description",
            dataIndex:'description',
            ellipsis: {
                showTitle: false,
            },
            render: (description) => (
                <Tooltip placement="topLeft" title={description}>
                    {description}
                </Tooltip>
            ),
        },
        {
            key: "updated_at",
            title: "Last updated at",
            width:'18%',
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {record.updated_at == null ? moment(record.created_at).format("DD/MM/YYYY hh:mm") : moment(record.updated_at).format("DD/MM/YYYY hh:mm")}
                        </div>
                    </>
                );
            },
        },
        {
            key: "status",
            title: "Status",
            width:'10%',
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {record.status == 1 ? (
                                <span className="badge bg-light-success">Active</span>
                            ) : (
                                <span className="badge bg-light-danger">Inactive</span>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            key: "action",
            title: "Actions",
            width:'10%',
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <button
                                className="badge badge-pill bg-light-danger action-btn"
                                style={{ color: "black" }}
                                onClick={() => Edit(record)}
                            >
                                <i class="ft-edit"></i>
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];

  useEffect(() => {
      loadDocuments();
  }, []);

  function loadDocuments(){
        spinner.style.display = "none";
        setLoading(true);
        const data = {id}
        getSpecificPolicies(data).then((response) => {
            setDocumentName(response.data.data[0].document_name)
            setTableData(response.data.data[0].company_document_categories);
            setLoading(false);
        });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
        data.append("category_name", category_name);
        data.append("description", description);
        data.append("category_id", categoryId);
        data.append("status", status);
        data.append("document_id", id);
        data.append("document_path", document_path);

        saveDocumentCategories(data).then((res) => {
            if (res.data.status_code == 1) {
                Swal.fire("success", ""+res.data.message, "success");
                setVisible(false);

                loadDocuments();
            } else {
                Swal.fire("warning", " " + res.data.message, "warning");
            }
        });
  };

  const handleNewDocumentForm = () => {
    setCategoryId(0);
    setCategoryName("");
    setStatus(1);
    setDescription("");
    setModalTitle("Add New Document");
    setVisible(true);
    if(document_path){
        inputRef.current.value = "";
    }
    setImageLabel("Document");
  };

    const Edit = (data) => {
        setCategoryId(data.id);
        setCategoryName(data.category_name);
        setStatus(data.status);
        setModalTitle("Edit Document - "+data.category_name);
        setExistedImage(data.document)
        if(document_path){
            inputRef.current.value = "";
        }
        setDocument("");
        setImageLabel("Replace Document");
        setDescription(data.description);
        setVisible(true);
    };

    const popupDocument = (url) => {
        let win = window.open(
            url,
            null,
            "popup,width=500,height=600,left=0,right=0"
        );
    };


    return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> {document_name} Document Categories</h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewDocumentForm(e)}
          >
            Add New
          </button>
        </div>
      </div>
      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                      <div className="table-responsive">
                          <div className="table">
                              <Table
                                  loading={loading}
                                  dataSource={tableData}
                                  columns={columns}
                                  pagination={{
                                      pageSize: 10,
                                      showSizeChanger: true,
                                      position: ["bottomCenter"],
                                  }}
                              />
                          </div>
                      </div>
                  </div>
                    <div className="card-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
          maskClosable={false}
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
          <div className="row">

          <div className="col-md-12">
              <label className="form-label">
                  Category Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                  type="text"
                  className="form-control"
                  value={category_name}
                  onChange={(e) => setCategoryName(e.target.value)}
              />
          </div>
              <div className="col-md-12">
                  <label className="form-label">
                      Description <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea className="form-control" rows={6} value={description}
                            onChange={(e) => setDescription(e.target.value)}
                  ></textarea>

              </div>
              <div className="col-md-6">
                  <label className="form-label">
                      {imageLabel} <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                      type="file"
                      name="document"
                      accept=".pdf"
                      className="form-control"
                      ref={inputRef}
                      onChange={(e) =>
                          setDocument(inputRef.current.files[0])
                      }
                  />
              </div>
              <div className="col-md-6">
                  <label className="form-label">
                      Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                      className="form-control"
                      name="status"
                      id="status"
                      value={status}
                      required
                      onChange={(e) => setStatus(e.target.value)}
                  >
                      <option value="1"> Active</option>
                      <option value="0"> Inactive</option>
                  </select>
              </div>

          </div>
      </Modal>
    </>
  );
}
export default PolicyCategory;

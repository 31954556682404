function Footer() {
  return (
    <footer className="footer undefined undefined">
      <p className="clearfix text-muted m-0">
        <span>Copyright &copy; {new Date().getFullYear()} &nbsp;</span>
        <a href="https://archnix.com/" id="pixinventLink" target="_blank">
          Archnix
        </a>
        <span className="d-none d-sm-inline-block">, All rights reserved.</span>
      </p>
    </footer>
  );
}

export default Footer;

import React, {useState, useEffect, useRef} from "react";

import Swal from "sweetalert2";
import {Modal} from "antd";
import "antd/dist/antd.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getPolicyDocumentList, storePolicyDocument} from "../services/PolicyDocumentAPIServices";
import DocumentCard from "../components/DocumentCard";
import {adminUrl} from "../UrlPaths";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style,  marginRight: "10px" }}
          onClick={onClick}
      />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style,  marginLeft: "10px",zIndex:"100" }}
          onClick={onClick}
      />
  );
}
function Policy() {
  const spinner = document.getElementById("spinner");
  const inputRef = useRef();
  const [document_name, setDocumentName] = useState("");
  const [status, setStatus] = useState("1");
  const [documentId, setDocumentId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Document");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");
  const [allMainDocuments, setAllMainDocuments] = useState([]);
  const [existedImage, setExistedImage] = useState("");
  const [imageLabel, setImageLabel] = useState("");
  const [currentImageDisplay, setCurrentImageDisplay] = useState("");

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


  useEffect(() => {
      loadMainDocument();
  }, []);

  function loadMainDocument(){
        spinner.style.display = "none";
        getPolicyDocumentList().then((response) => {
            setAllMainDocuments(response);
        });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
        data.append("document_name", document_name);
        data.append("document_id", documentId);
        data.append("status", status);
        data.append("image", image);

        storePolicyDocument(data).then((res) => {
            if (res.data.status_code == 1) {
                Swal.fire("success", ""+res.data.message, "success");
                setVisible(false);
                setLoading(true);
                loadMainDocument();
            } else {
                Swal.fire("warning", " " + res.data.message, "warning");
            }
        });
  };

  const handleNewDocumentForm = () => {
    setDocumentId(0);
    setDocumentName("");
    setStatus(1);
    setModalTitle("Add New Document");
    setVisible(true);
    if(image){
        inputRef.current.value = "";
    }
    setCurrentImageDisplay("none");
    $('#image-preview-area').hide();
    setImageLabel("Image");
  };

    const Edit = (data) => {
        setDocumentId(data.id);
        setDocumentName(data.document_name);
        setStatus(data.status);
        setModalTitle("Edit Document - "+data.document_name);
        setExistedImage(data.image)
        if(image){
            inputRef.current.value = "";
        }
        setImage("");
        setImageLabel("Replace Image");
        $('#image-preview-area').show();
        setCurrentImageDisplay("");
        setVisible(true);
    };

    return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Company Main Document Sections</h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewDocumentForm(e)}
          >
            Add New
          </button>
        </div>
      </div>
      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">

                      <Slider {...settings}>

                          {allMainDocuments.length === 0 ? (<>
                              <div> <h6>No document found...</h6></div>
                              </>):(
                              allMainDocuments.map((document, index) => (
                                  <>
                                  <span className="card-edit">
                                        <span>
                                            <button
                                                className="badge badge-pill bg-light-danger action-btn"

                                                onClick={() => Edit(document)}
                                            >
                                            <i className="ft-edit"></i>
                                          </button>
                                    </span>
                                  </span>
                                  <DocumentCard id={document.id} name={document.document_name} imagePath={document.image} d_status={document.status}/>
                                  </>))
                          )}
                      </Slider>
                  </div>
                    <div className="card-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
          maskClosable={false}
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
          <div className="row">
              <div className="col-md-12 text-center" id="image-preview-area" style={{display:currentImageDisplay}}>

                  <img alt={'document-image'} className="img-fluid" width={150} src={`${adminUrl}${existedImage}`}/>
              </div>
          <div className="col-md-12">
              <label className="form-label">
                  Document Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                  type="text"
                  className="form-control"
                  value={document_name}
                  onChange={(e) => setDocumentName(e.target.value)}
              />
          </div>
              <div className="col-md-6">
                  <label className="form-label">
                      {imageLabel} <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                      type="file"
                      name="document"
                      accept="image/*"
                      className="form-control"
                      ref={inputRef}
                      onChange={(e) =>
                          setImage(inputRef.current.files[0])
                      }
                  />
              </div>
              <div className="col-md-6">
                  <label className="form-label">
                      Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                      className="form-control"
                      name="status"
                      id="status"
                      value={status}
                      required
                      onChange={(e) => setStatus(e.target.value)}
                  >
                      <option value="1"> Active</option>
                      <option value="0"> Inactive</option>
                  </select>
              </div>

          </div>
      </Modal>
    </>
  );
}
export default Policy;

import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';


export async function getAnnouncementList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllAnnouncements',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getSpecificAnnouncementData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getSpecificAnnouncement`,data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function storeAnnouncementData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/storeAnnouncement`, data,
        { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
    return error;
  }
}

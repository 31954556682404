import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';


export async function getPolicyDocumentList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllCompanyDocument',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}


export async function storePolicyDocument(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/storeCompanyDocument`, data,
        { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getSpecificPolicies(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/getSpecificPolicies`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
    return error;
  }
}

export async function saveDocumentCategories(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/saveDocumentCategories`, data,
        { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'} });

    return response;
  } catch (error) {
    return error;
  }
}


import React, {useEffect, useRef, useState} from "react";

import Swal from "sweetalert2";
import {Button, Input, Modal, Table} from "antd";
import "antd/dist/antd.css";
import {adminUrl} from "../../UrlPaths";
import {SearchOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {createCompanyData, getAllCompaniesList} from "../../services/OrganizationAPIServices";
import {Link} from "react-router-dom";

function Organization() {
    const spinner = document.getElementById("spinner");
    const inputRef = useRef();
    const [document_name, setDocumentName] = useState("");
    const [status, setStatus] = useState("1");
    const [documentId, setDocumentId] = useState("0");
    const [modalTitle, setModalTitle] = useState("Add New Organization");
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [image, setImage] = useState("");
    const [existedImage, setExistedImage] = useState("");
    const [imageLabel, setImageLabel] = useState("");
    const [currentImageDisplay, setCurrentImageDisplay] = useState("");
    const [tableData, setTableData] = useState([]);

    const columns = [
        {
            key: "company_full_name",
            title: "Company",
            filterMode: "tree",
            filterSearch: true,
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            <img
                                src={`${adminUrl}${record.logo_path}`}
                                className="mr-2"
                                alt="logo"
                                width="30"
                                height="30"
                            />
                            <span>{record.company_full_name}</span>
                        </div>
                    </>
                );
            },
            filterDropdown: ({
                                 setSelectedKeys,
                                 selectedKeys,
                                 confirm,
                                 clearFilters,
                             }) => {
                return (
                    <>
                        <Input
                            autoFocus
                            placeholder="Type company"
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({closeDropDown: false});
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        ></Input>
                        <Button
                            onClick={() => {
                                confirm();
                            }}
                            type="primary"
                        >
                            Search
                        </Button>
                    </>
                );
            },
            filterIcon: () => {
                return <SearchOutlined/>;
            },
            onFilter: (value, record) => {
                return record.company_full_name
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
        },
        {
            key: "address",
            title: "Address",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <span className="">{record.address}</span><br/>
                            <span className="text-muted">{record.contact_no} / </span>
                            <span className="text-muted"><a className="text-muted" target={"_blank"}
                                                            href={record.website}>{record.website}</a></span>
                        </div>
                    </>
                );
            },
        },
        {
            key: "created_at",
            title: "Created At",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {moment(record.created_at).format("DD/MM/YYYY")}
                        </div>
                    </>
                );
            },
        },
        {
            key: "status",
            title: "Status",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {record.status == 1 ? (
                                <span className="badge bg-light-success">Active</span>
                            ) : (
                                <span className="badge bg-light-danger">Inactive</span>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            key: "action",
            title: "Actions",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <Link to={`/organization-edit/${record.id}`}>
                                <button className="badge badge-pill bg-light-danger action-btn">
                                    <i class="ft-edit"></i>
                                </button>
                            </Link>
                        </div>
                    </>
                );
            },
        },
    ];
    useEffect(() => {
        loadOrganizations();
    }, []);

    function loadOrganizations() {

        setLoading(true);
        getAllCompaniesList().then((response) => {
            spinner.style.display = "none";
            setTableData(response);
            setLoading(false);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("document_name", document_name);
        data.append("document_id", documentId);
        data.append("status", status);
        data.append("image", image);

        createCompanyData(data).then((res) => {
            if (res.data.status_code == 1) {
                Swal.fire("success", "" + res.data.message, "success");
                setVisible(false);
                setLoading(true);
                loadMainDocument();
            } else {
                Swal.fire("warning", " " + res.data.message, "warning");
            }
        });
    };

    const handleNewDocumentForm = () => {
        setDocumentId(0);
        setDocumentName("");
        setStatus(1);
        setModalTitle("Add New Organization");
        setVisible(true);
        if (image) {
            inputRef.current.value = "";
        }
        setCurrentImageDisplay("none");
        $('#image-preview-area').hide();
        setImageLabel("Image");
    };

    const Edit = (data) => {
        setDocumentId(data.id);
        setDocumentName(data.document_name);
        setStatus(data.status);
        setModalTitle("Edit Organization - " + data.document_name);
        setExistedImage(data.image)
        if (image) {
            inputRef.current.value = "";
        }
        setImage("");
        setImageLabel("Replace Image");
        $('#image-preview-area').show();
        setCurrentImageDisplay("");
        setVisible(true);
    };

    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3> Organizations</h3>
                </div>
                <div className="col-4">
                    <Link to="/new-organization">
                        <button
                            type="button"
                            className="btn btn-primary mb-3 d-block  float-right"
                        >
                            Add New
                        </button>
                    </Link>

                </div>
            </div>
            <section className="users-list-wrapper">
                <div className="users-list-table">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <Table
                                            loading={loading}
                                            dataSource={tableData}
                                            columns={columns}
                                            pagination={{
                                                pageSize: 10,
                                                showSizeChanger: true,
                                                position: ["bottomCenter"],
                                            }}
                                        />
                                    </div>
                                    <div className="card-footer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                okText="Save"
                onCancel={() => setVisible(false)}
                onOk={(e) => {
                    handleSubmit(e);
                }}
            >
                <div className="row">
                    <div className="col-md-12 text-center" id="image-preview-area"
                         style={{display: currentImageDisplay}}>

                        <img alt={'document-image'} className="img-fluid" width={150}
                             src={`${adminUrl}${existedImage}`}/>
                    </div>
                    <div className="col-md-12">
                        <label className="form-label">
                            Document Name <span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={document_name}
                            onChange={(e) => setDocumentName(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            {imageLabel} <span style={{color: "red"}}>*</span>
                        </label>
                        <input
                            type="file"
                            name="document"
                            accept="image/*"
                            className="form-control"
                            ref={inputRef}
                            onChange={(e) =>
                                setImage(inputRef.current.files[0])
                            }
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            Status <span style={{color: "red"}}>*</span>
                        </label>
                        <select
                            className="form-control"
                            name="status"
                            id="status"
                            value={status}
                            required
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="1"> Active</option>
                            <option value="0"> Inactive</option>
                        </select>
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default Organization;

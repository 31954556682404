import React, { useState, useEffect } from "react";
import { getLoggedUserDetails } from "../services/AuthAPIServices";
import NormalAttendance from "../components/NormalAttendance";
import HourlyAttendance from "../components/HourlyAttendance";
function Attendance() {
  const user_id = localStorage.getItem("hms_user_id");
  const [attendance_type, setAttendanceType] = useState("0");
  const [editAccess, setEditAccess] = useState(false);

  useEffect(() => {
    const user = {
      user_id,
    };
    getLoggedUserDetails(user).then((response) => {
      setAttendanceType(response.data.company.attendance_type);
      if(response.data.user.user_role == 2){
        setEditAccess(true);
      }else{
        setEditAccess(false);
      }
    });
  }, [user_id]);

  return (
    <>
     <div className="row">
        <div className="col-12">
          <h3> Attendance </h3>
        </div>
      </div>
      {attendance_type == 1 ? (<NormalAttendance/>):(<HourlyAttendance editAccess={editAccess}/>)}
    </>
  );
}
export default Attendance;

import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getAllMessages() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllMessages',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMessageReplies(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getMessageReplies',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function storeNewMessage(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/sendMessage', data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
   
    return error;
  }
}

export async function storeReplyMessage(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/storeReplyMessage', data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
   
    return error;
  }
}

export async function closeThreadMsg(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/closeThreadMsg',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

import React, { useState, useEffect, useRef } from "react";
import {
  getTeams,
  getAllTeamLeaders,
  getAllTeamCoordinator,
  teamActions,
} from "../../services/MasterFileAPIServices";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import {adminUrl} from "../../UrlPaths";
import { Link } from "react-router-dom";

function Teams() {
  const inputRef = useRef();
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [leadersList, setLeadersList] = useState([]);
  const [coordinatorsList, setCoordinatorsList] = useState([]);
  const [team_name, setTeamName] = useState("");
  const [leader, setLeader] = useState("");
  const [coordinator, setCoordinator] = useState("");
  const [status, setStatus] = useState("");
  const [team_id, setTeamId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Team");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState("");
  const [existedImage, setExistedImage] = useState("");
  const [iconLabel, setIconLabel] = useState("Icon");

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "team_name",
      title: "Team name",
      filterMode: "tree",
      filterSearch: true,
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
            <img alt={'icon'} className="img-fluid" width={30}
                src={`${adminUrl}${record.icon}`} /><b>&nbsp; {record.team_name}</b>
            </div>
          </>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type team name"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.team_name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "leader_name",
      title: "Leader name",
      dataIndex: "leader_name",
    },
    {
      key: "coordinator_name",
      title: "Coordinator name",
      dataIndex: "coordinator_name",
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-danger action-btn"
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
              <Link to={`/existing-employees`} state={{ search: record.team_name }}>
              <button
                className="badge badge-pill bg-light-info action-btn ml-1"
              >
                <i class="ft-search"></i>
              </button>
              </Link>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getTeams().then((response) => {
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });

    getAllTeamLeaders().then((response) => {
      setLeadersList(response);
    });

    getAllTeamCoordinator().then((response) => {
      setCoordinatorsList(response);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("team_name", team_name);
    data.append("leader", leader);
    data.append("coordinator", coordinator);
    data.append("status", status);
    data.append("team_id", team_id);
    data.append("icon", icon);


    teamActions(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("success", "Successfully updated", "success");
        setTeamId(0);
        setTeamName("");
        setLeader("");
        setCoordinator("");
        setStatus(1);
        setModalTitle("Add New Team");
        setVisible(false);
        if (icon) {
          inputRef.current.value = "";
        }
        setLoading(true);

        getTeams().then((response) => {
          setTableData(response);
          setLoading(false);
        });
      } else {
        Swal.fire("warning", " " + response.message, "warning");
      }
    });
  };

  const handleNewTeamForm = (e) => {
    e.preventDefault();
    setTeamId(0);
    setTeamName("");
    setLeader("");
    setCoordinator("");
    setStatus(1);
    setIconLabel("Icon");
    setExistedImage("");
    $('#icon-img-section').hide();
    setModalTitle("Add New Team");
    setVisible(true);
  };
  const Edit = (data) => {
    setTeamId(data.id);
    setTeamName(data.team_name);
    setLeader(data.leader == null ? "" : data.leader);
    setCoordinator(data.coordinator == null ? "" : data.coordinator);
    setStatus(data.status);
    setExistedImage(data.icon)
    setIconLabel("Replace icon")
    setModalTitle("Edit Team Details");
    setVisible(true);
    $('#icon-img-section').show();
  };
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Teams Master File</h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            class="btn btn-primary mb-3 d-block  float-right"
            data-toggle="modal"
            data-target="#teamModal"
            onClick={(e) => handleNewTeamForm(e)}
          >
            Add New<i class="ft-plus ml-2"></i>
          </button>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
                <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
        <div className="row">

          <div className="col-md-12">
          <label className="form-label">
            Team Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="team_name"
            id="team_name"
            value={team_name}
            onChange={(e) => setTeamName(e.target.value)}
            required
          />
          </div>
         
          <div className="col-md-8">
          <label>
            {iconLabel}  {existedImage == "" ? (<><span style={{ color: "red" }}>*</span></>):(<></>)}
          </label>
          <input
            type="file"
            name="document"
            accept="image/png"
            className="form-control"
            ref={inputRef}
            onChange={(e) =>
              setIcon(inputRef.current.files[0])
            }
          />
          </div>

          <div className="col-md-4" id="icon-img-section">
            {existedImage != "" ? (<>
              <div className="p-2">
              <img alt={'icon'} className="img-fluid" width={50}
                src={`${adminUrl}${existedImage}`} />
            </div>
            </>):(<></>)}
          
          </div>
          <div className="col-md-12">
          <label className="form-label">
            Leader 
          </label>
          <select
            className="form-control"
            name="leader"
            id="leader"
            value={leader}
            required
            onChange={(e) => setLeader(e.target.value)}
          >
            <option value="" disabled>
              select leader
            </option>
            {leadersList.map((item, i) => {
              return <option value={item.id}> {item.name_with_initial}</option>;
            })}
          </select>
          </div>
          <div className="col-md-12">
          <label className="form-label">
            Coordinator 
          </label>
          <select
            className="form-control"
            name="coordinator"
            id="coordinator"
            value={coordinator}
            required
            onChange={(e) => setCoordinator(e.target.value)}
          >
            <option value="" disabled>
              select coordinator
            </option>
            {coordinatorsList.map((item, i) => {
              return <option value={item.id}> {item.name_with_initial}</option>;
            })}
          </select>
          </div>
          <div className="col-md-12">
          <label className="form-label">
            Status <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control"
            name="status"
            id="status"
            value={status}
            required
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="1"> Active</option>
            <option value="0"> Inactive</option>
          </select>
          </div>
          <input
            type="hidden"
            className="form-control"
            name="team_id"
            id="team_id"
            value={team_id}
            onChange={(e) => setTeamId(e.target.value)}
            required
          />
        </div>
        </form>
      </Modal>
    </>
  );
}
export default Teams;

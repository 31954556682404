import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { requestAccountDelete } from "../services/UserAPIServices";


function RequestAccountDeletion() {

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setPhone] = useState("");
    const [reason, setRemark] = useState("");

    function requestDataSubmit() {
        var phoneno = /^\d{10}$/;
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (first_name == "" || last_name == "" || email == "" || !emailRegex.test(email) || mobile == "" || reason == "" || !mobile.match(phoneno)) {
            if (first_name == "") {
                Swal.fire("Warning", "Please enter first name..!", "warning");
            } else if (last_name == "") {
                Swal.fire("Warning", "Please enter last name..!", "warning");
            } else if (email == "" || !emailRegex.test(email)) {
                Swal.fire("Warning", "Please enter valid email..!", "warning");
            } else if (reason == "") {
                Swal.fire("Warning", "Please enter reason..!", "warning");
            } else {
                Swal.fire("Warning", "Please enter valid mobile number..!", "warning");
            }
        } else {
            const data = {
                email,
                mobile,
                first_name,
                last_name,
                reason
            };

            requestAccountDelete(data).then((response) => {
              
                if (response.status_code == 1) {
                    Swal.fire("Success", "" + response.message, "success").then(function () {
                        fromReset();
                    });
                } else {
                    Swal.fire("Warning", "" + response.message, "warning");
                }
            }).catch((error) => {
                Swal.fire("Warning", "" + response.message, "warning");
            });

        }
    }

    function fromReset() {

        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setRemark("");
    }
    return (
        <div style={{ background: "#dadee3", overflow: 'hidden' }}>
            <section className="auth-height">
                <div className="">
                    <div className="row full-height-vh m-0 d-flex align-items-center justify-content-center">
                        <div className="col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                            <div className="card card-coming-soon W overflow-hidden">
                                <div className="card-header text-center pt-4 pb-1">
                                    <img alt="avtar" className="img-fluid" src="app-assets/img/logo.jpg" width="150" />
                                </div>
                                <div id="clockFlat" className="getting-started px-sm-2"></div>
                                <div claclassNamess="card-content">
                                    <div className="card-body text-center pt-1">
                                        <h4 className="mb-4 mt-2">Please fill the form to proceed the delete request</h4>

                                        <div className="mx-4">
                                            <div className="form-group">

                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="text" className="form-control" id="iconLeft1" placeholder="First Name" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                                                    <div className="form-control-position">
                                                        <i class="ft-user"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="text" className="form-control" id="iconLeft1" placeholder="Last Name" value={last_name} onChange={(e) => setLastName(e.target.value)} />
                                                    <div className="form-control-position">
                                                        <i class="ft-user"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="email" className="form-control" id="iconLeft1" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <div className="form-control-position">
                                                        <i class="ft-mail"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="text" className="form-control" id="iconLeft1" placeholder="Mobile Number" value={mobile} onChange={(e) => setPhone(e.target.value)} />
                                                    <div className="form-control-position">
                                                        <i class="ft-smartphone"></i>
                                                    </div>
                                                    <small style={{ float: 'left' }} className="text-muted">*Format: xxxxxxxxxx</small>
                                                </fieldset>
                                                <textarea className="form-control" value={reason} onChange={(e) => setRemark(e.target.value)} rows="5" placeholder="Reason for the deletion"></textarea>
                                            </div>

                                            <div className="d-flex flex-sm-row flex-column justify-content-center">

                                                <button type="button" className="btn btn-primary" onClick={requestDataSubmit}>
                                                    Request to delete account
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default RequestAccountDeletion;

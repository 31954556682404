import React, { useState, useEffect, useRef } from "react";
import { Input, Table, Button, DatePicker, Select } from "antd";
import "antd/dist/antd.css";
import { getAttendanceData } from "../services/AttendanceAPIServices";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import tableExport from "antd-table-export";
import moment from "moment";
import Swal from "sweetalert2";
import { getActiveTeams } from "../services/MasterFileAPIServices";
import { getCompanyMasterLeaveTypes } from "../services/EmployeeAPIServices";
import dayjs from 'dayjs';

function NormalAttendance() {
  var current_year_month = "";
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const date = new Date().getDate();

  if (month < 10) {
    current_year_month = year + "-0" + month;
  } else {
    current_year_month = year + "-" + month;
  }

  const { RangePicker } = DatePicker;

  const spinner = document.getElementById("spinner");
  const [sending_month, setMonth] = useState(current_year_month);
  const [loading, setLoading] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [filterFrom, setFilterFrom] = useState(moment().startOf("month").format('YYYY-MM-DD'));
  const [filterTo, setFilterTo] = useState(moment().format('YYYY-MM-DD'));
  const [teamList, setTeamList] = useState([]);
  const [teamID, setTeamID] = useState('0');
  const [leave_type_list, setLeaveTypesList] = useState([]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const tableName = "attendance_sheet";

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "emp_id",
      fixed: 'left',
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "emp_name",
      filterMode: "tree",
      fixed: 'left',
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type employee name"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.emp_name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: monthName,
      children: columnsData.map((attendance) => {
        const { date, day, count } = attendance;
        return {
          title: date + " " + day,
          dataIndex: "dateAttendance",
          key: count,
          align: "center",
          render: (values) =>
            values.map((value, index) => {
              let ret;
              let color_code = values[index].color;
              if (index === count - 1) ret = values[index].attend;
              return (
                <>
                  {ret == "CA" ? (
                    <div className="flex" style={{ background: `${color_code}`, width: "20px", height: "20px" }}>
                    </div>
                  ) : ret == "AN" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : ret == "SI" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : ret == "SH" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : ret == "MA" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : ret == "HM" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : ret == "HE" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : ret == "P" ? (
                    <div style={{ background: `${color_code}`, width: "20px", height: "20px" }}></div>
                  ) : (
                    <div className="flex" style={{ background: `${color_code}`, width: "20px" }}>
                      { }
                    </div>
                  )}
                </>
              );
            }),
        };
      }),
    },
  ];
  const exportInstance = new tableExport(rowData, columns);
  //  exportInstance.download(tableName, "xlsx");

  useEffect(() => {
    let monthIndex = new Date().getMonth();
    let mo = monthNames[monthIndex];
    setMonthName( moment(filterFrom).format("DD/MM/YYYY")+" - "+moment(filterTo).format("DD/MM/YYYY"));
    const data = {
      filterFrom,
      filterTo,
      teamID
    };

    setLoading(true);
    getAttendanceData(data).then((response) => {
      if (response.employeeAttendanceArray.length > 0) {
        setColumnsData(response.employeeAttendanceArray[0].dateAttendance);
        setRowData(response.employeeAttendanceArray);
      } else {
        setColumnsData(response.employeeAttendanceArray);
        setRowData(response.employeeAttendanceArray);
      }

      spinner.style.display = "none";
      setLoading(false);
    });

    getActiveTeams().then((res) => {
      let newRow = { 'value': '0', 'label': 'All Teams' };
      res.push(newRow);
      setTeamList(res.reverse());
    });

    getCompanyMasterLeaveTypes().then((response) => {
      setLeaveTypesList(response.data.data);
    });
  }, [teamID]);

  function searchMonthAttendance(event) {

    setMonthName( moment(filterFrom).format("DD/MM/YYYY")+" - "+moment(filterTo).format("DD/MM/YYYY"));

    if (filterFrom == "" || filterFrom == null || filterTo == "" || filterTo == null) {
      Swal.fire("warning", "Please select date range", "warning");
    } else {
      const monthData = {
        filterFrom,
        filterTo,
        teamID
      };

      setLoading(true);
      getAttendanceData(monthData).then((response) => {

        if (response.employeeAttendanceArray.length > 0) {
          setColumnsData(response.employeeAttendanceArray[0].dateAttendance);
          setRowData(response.employeeAttendanceArray);
        } else {
          setColumnsData(response.employeeAttendanceArray);
          setRowData(response.employeeAttendanceArray);
        }


        setLoading(false);
      });
    }
  }

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setFilterTo(dateStrings[1]);
      setFilterFrom(dateStrings[0]);
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      // console.log("From:cc ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
      setFilterTo("");
      setFilterFrom("");
    }
  };
  const handleChange = (value) => {

    setTeamID(value);
    searchMonthAttendance();

  };

  const disabledDate = (current) => {

    return current && current > dayjs().endOf('day');
  };

  return (
    <>
     
      <div className="users-list-filter px-2 align-center">
        <form>
          <div className="row border rounded py-2 mb-2 mx-n2">

            <div className="col-12 col-sm-6 col-lg-3">
              <label for="users-list-role">Team Selection</label>
              <Select
                defaultValue="0"
                style={{
                  width: '100%',
                }}
                onChange={handleChange}
                options={teamList}
                value={teamID}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3"></div>
            <div className="col-12 col-sm-6 col-lg-4">
              <label for="users-list-role">Date Period</label>
              <fieldset className="form-group">
                <RangePicker
                  className="form-control"
                  disabledDate={disabledDate}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment(),
                    ],
                    "Last 7 Days": [
                      moment().subtract(7, 'd'), moment()
                    ]
                  }}
                  onChange={onChange}
                />
                {/* <input
                  type="month"
                  className="form-control pickadate"
                  value={sending_month}
                  onChange={(e) => setMonth(e.target.value)}
                /> */}
              </fieldset>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-primary btn-block users-list-clear glow mb-0"
                onClick={searchMonthAttendance}
              >
                <i className="ft-search"></i> Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
              <div className="row">
                {/* <CSVLink
                  filename={"attendance.csv"}
                  data={rowData}
                  onClick={() => {
                    console.log("clicked");
                  }}
                >
                  Download csv
                </CSVLink> */}
                
                <div className="col-md-12 mb-2">
                  <div className="d-flex" style={{ flexWrap: "wrap"}}>
                  <div className="d-flex" style={{width: '165px'}}>
                          <div style={{ background:'#34eb83', width: "20px", height: "20px" }}></div>
                          <span className="ml-1"> Work</span>
                          </div>
                
                    {leave_type_list.map((item, i) => {
                      return (
                        <>
                          <div className="d-flex" style={{width: '165px'}}>
                          <div style={{ background: `${item.color}`, width: "20px", height: "20px" }}></div>
                          <span className="ml-1"> {item.leave_type}</span>
                          </div>
                        </>);
                    })}
                    <div className="d-flex" style={{width: '165px'}}>
                       <div style={{ background:'#8904dc', width: "20px", height: "20px" }}></div>
                          <span className="ml-1"> Half Day - Morning</span>
                          </div>
                          <div className="d-flex" style={{width: '165px'}}>
                          <div style={{ background:'#000000', width: "20px", height: "20px" }}></div>
                          <span className="ml-1"> Half Day - Evening</span>
                          </div>
                  </div>
                  </div>
               
                <div className="col-12">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        columns={columns}
                        dataSource={rowData}
                        pagination={{
                          pageSize: 20,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NormalAttendance;

import React, {useState, useEffect, useRef} from "react";

import Swal from "sweetalert2";
import {Table, Modal, Input, Button, Select} from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import {getAnnouncementList, storeAnnouncementData} from "../services/AnnouncementAPIServices";
import moment from "moment";
import {adminUrl} from "../UrlPaths";
import {getActiveEmployees} from "../services/EmployeeAPIServices";

function Announcement() {
  const spinner = document.getElementById("spinner");
  const inputRef = useRef();
  const { Option } = Select;
  const [tableData, setTableData] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("1");
  const [announcement, setAnnouncement] = useState("");
  const [announcementId, setAnnouncementId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Announcement");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");
  const [employee_id, setEmployeeId] = useState("0");
  const [employeeList, setEmployeeList] = useState([]);
  const [empDisabled, setEmpDisabled] = useState(true);
  const [allEmpDisabled, setAllEmpDisabled] = useState(false);
  const [existedImage, setExistedImage] = useState("");
  const [imageLabel, setImageLabel] = useState("");
  const [currentImageDisplay, setCurrentImageDisplay] = useState("");

  const columns = [
    {
      key: "title",
      title: "Title",
      filterMode: "tree",
      filterSearch: true,
      render: (record) => {
        return (
            <>
              {" "}
              <div className="flex">
                {record.image != "" && record.image != null ? (<>
                  <img
                    src={`${adminUrl}${record.image}`}
                    className="mr-2" 
                    alt="announcement image"
                    width="30"
                    height="30"
                />
                </>):(<></>)}
             
                <span>{record.title}</span>
              </div>
            </>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type title"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.title
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      key: "type",
      title: "Type",
      render: (record) => {
        return (
            <>
              {" "}
              <div className="flex">
                {record.type == 1 ? (
                    <span className="badge badge-success">Public</span>
                ) : (<>
                    <span className="badge badge-warning">Private</span><br/>
                    <span className="text-muted">To: {record.name_with_initial}</span>
                    </>
                )}
              </div>
            </>
        );
      },
    },
    {
      key: "created_at",
      title: "Created At",
      render: (record) => {
        return (
            <>
              {" "}
              <div className="flex">
                {moment(record.created_at).format("DD/MM/YYYY")}
              </div>
            </>
        );
      },
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="badge badge-pill bg-light-danger action-btn"
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadData();

      getActiveEmployees().then((response) => {
          setEmployeeList(response.data.data.records);
      });
  }, []);


  function loadData(){
      setLoading(true);
      getAnnouncementList().then((response) => {
        spinner.style.display = "none";
        setTableData(response);
        setLoading(false);
      });

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    if(type == '2' && employee_id == ""){
        Swal.fire("warning", "Please select employee name.", "warning");
    }else{
        data.append("title", title);
        data.append("announcement_id", announcementId);
        data.append("annoucement", announcement);
        data.append("type", type);
        data.append("status", status);
        data.append("employee_id", employee_id);
        data.append("image", image);

        storeAnnouncementData(data).then((res) => {
            if (res.data.status_code == 1) {
                Swal.fire("success", ""+res.data.message, "success");
                setVisible(false);
                setLoading(true);
                loadData();
            } else {
                Swal.fire("warning", " " + res.data.message, "warning");
            }
        });
    }


  };

  const handleNewAnnouncementForm = (e) => {
    setAnnouncementId(0);
    setTitle("");
    setStatus(1);
    setType("1");
    setEmployeeId("0");
    setAnnouncement("");
    setModalTitle("Add New Announcement");
    setVisible(true);
    setImageLabel("Image");
    if(image){
          inputRef.current.value = "";
    }
    setCurrentImageDisplay("none");
    $('#image-preview-area').hide();
  };

  const Edit = (data) => {
    setAnnouncementId(data.id);
    setTitle(data.title);
    setStatus(data.status);
    onChangeType(data.type);
    setAnnouncement(data.annoucement);
    setEmployeeId(data.employee_id);
    setModalTitle("Edit Announcement");
    setVisible(true);
      setExistedImage(data.image)
      if(image){
          inputRef.current.value = "";
      }
      setImage("");
      setImageLabel("Replace Image");
      $('#image-preview-area').show();
      setCurrentImageDisplay("");
  };

    const onSearch = (value) => {
        // console.log('search:', value);
    };

    const onChangeEmp = (value) => {
        setEmployeeId(value);
    };

    const onChangeType = (value) => {
        if(value == "1") {
            setEmployeeId("0");
            setEmpDisabled(true);
            setAllEmpDisabled(false);
        }else{
            setEmployeeId("");
            setEmpDisabled(false);
            setAllEmpDisabled(true)
        }
        setType(value);
    };

    return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Announcements</h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewAnnouncementForm(e)}
          >
            Add New
          </button>
        </div>
      </div>
      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          dataSource={tableData}
                          columns={columns}
                          pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
          maskClosable={false}
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
      >
          <div className="row">
            {existedImage != "" ? (<>
              <div className="col-md-12 text-center" id="image-preview-area" style={{display:currentImageDisplay}}>
                  <img alt={'document-image'} className="img-fluid" width={250} src={`${adminUrl}${existedImage}`}/>
              </div>
            </>):(<></>)}
              
          <div className="col-md-12">
              <label className="form-label">
                  Title <span style={{ color: "red" }}>*</span>
              </label>
              <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
              />
          </div>
          <div className="col-md-4">
              <label className="form-label">
                  Type <span style={{ color: "red" }}>*</span>
              </label>
              <select
                  className="form-control"
                  value={type}
                  onChange={(e) => onChangeType(e.target.value)}
              >
                  <option value="1"> Public</option>
                  <option value="2"> Private</option>
              </select>
          </div>
              <div className="col-md-8">
                  <label className="form-label">
                      Employee <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                      showSearch
                      disabled={empDisabled}
                      className="form-control"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      onChange={onChangeEmp}
                      value={employee_id}
                      filterOption={(input, option) =>
                          option.children
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                      }
                  >
                      <Option value="0" disabled={allEmpDisabled}>All</Option>
                      {employeeList.map((item, i) => {
                          return (
                              <Option value={item.emp_id}>
                                  {item.name}
                              </Option>
                          );
                      })}
                  </Select>
              </div>
              <div className="col-md-12">
                  <label className="form-label">
                      Announcement <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea className="form-control" rows={5} value={announcement}
                            onChange={(e) => setAnnouncement(e.target.value)}
                  ></textarea>

              </div>
              <div className="col-md-6">
                  <label className="form-label">
                      {imageLabel} 
                  </label>
                  <input
                      type="file"
                      name="document"
                      accept="image/*"
                      className="form-control"
                      ref={inputRef}
                      onChange={(e) =>
                          setImage(inputRef.current.files[0])
                      }
                  />
              </div>
              <div className="col-md-6">
                  <label className="form-label">
                      Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                      className="form-control"
                      name="status"
                      id="status"
                      value={status}
                      required
                      onChange={(e) => setStatus(e.target.value)}
                  >
                      <option value="1"> Active</option>
                      <option value="0"> Inactive</option>
                  </select>
              </div>


          </div>
      </Modal>

    </>
  );
}
export default Announcement;

import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';


export async function requestAccountDelete(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/deleteRequest', data,
      { headers: { "Content-Type": "application/json"} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}


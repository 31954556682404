import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';




export async function getDashboardData(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getDashboardData',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
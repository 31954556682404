import {adminUrl} from "../UrlPaths";
import {Link} from "react-router-dom";

function DocumentCard({id,name,imagePath,d_status}) {
  return (<>
      <div className="col-md-12">
      <div className="card card-inverse bg-secondary text-center border-0 box-shadow-0">
          <div className="card-content">
              <Link to={`/policies/${id}`}>
              <div className="card-body" >
                  <img src={`${adminUrl}${imagePath}`} alt="image" width="225" className="mb-3 img-fluid"/>
                      <h3 className="card-title">{name}</h3>
                  {d_status == 1 ? (
                      <span className="badge badge-pill bg-success">Active</span>
                  ) : (
                      <span className="badge badge-pill bg-danger">Inactive</span>
                  )}

              </div>
              </Link>
          </div>
      </div>
      </div>
  </>);
}

export default DocumentCard;

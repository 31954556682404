import { empDocumentUrl, websiteUrl } from "../UrlPaths";
import Slider from "react-slick";
import { Document, pdfjs } from 'react-pdf'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style,  marginRight: "16px" }}
            onClick={onClick}
        />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style,  marginLeft: "15px",zIndex:"100" }}
            onClick={onClick}
        />
    );
  }
  
function EmployeeDocumentCard({ docArray, page, decisionStore,deleteDocument }) {

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    var settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        // initialSlide: 0,
        // responsive: [
        //     {
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             dots: true
        //         }
        //     },
        //     {
        //         breakpoint: 600,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             initialSlide: 1
        //         }
        //     },
        //     {
        //         breakpoint: 480,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1
        //         }
        //     }
        // ]
    };



    const popupDocument = (url) => {
        let win = window.open(
            url,
            null,
            "popup,width=500,height=600,left=0,right=0"
        );
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    return (<>
        <Slider {...settings}>
            {docArray.map((list, i) => {
                let exe = getExtension(list.document_path)
                // documentsList.push({ "uri": `${empDocumentUrl}/${list.document_path}`, });
                return (
                    <> <div className="mb-1">
                        <div className="d-flex justify-content-between p-1"><span className="float-left">Doc: {i + 1} / {docArray.length}</span><span className="float-right">{list.is_employee_updated == 0 ? 'Admin Uploaded' : 'Employee Uploaded'}</span> {page == 'edit-employee' ? <button className="btn badge badge-pill bg-light-danger pl-1" onClick={() => deleteDocument(`${list.id}`)}><i class="fa fa-trash" aria-hidden="true"></i></button> : ''} </div>
                        {exe.toLowerCase() == 'pdf' ? (<>
                            <div className="d-flex justify-content-center align-items-center" style={{width: '100%', height: '220px', textAlign:'center' }}>
                                <div className="iframe-container"  onClick={() => popupDocument(`${empDocumentUrl}/${list.document_path}`)} style={{ cursor: 'pointer' }}>
                                <img src={`${websiteUrl}app-assets/img/pdf-format.png`} alt="image" style={{width: '100%', height: '100px',display: 'block', marginLeft: 'auto', marginRight: 'auto' }} className="img-fluid" />
                                    <small className="text-muted">{list.document_path}</small>
                                    {/* <iframe
                                        src={`${empDocumentUrl}/${list.document_path}`}
                                        className="pdf-frame"
                                        //  onLoad={resizeIframe(this)}
                                         frameBorder="0"
                                         scrolling="no"
                                    /> */}
                                     {/* <embed src={`${empDocumentUrl}/${list.document_path}`} type="application/pdf" /> */}
                                    {/* <Document file={`${empDocumentUrl}/${list.document_path}`}   /> */}
                                </div>
                            </div>
                        </>) : (<>
                            <div>
                                <div onClick={() => popupDocument(`${empDocumentUrl}/${list.document_path}`)} style={{ cursor: 'pointer'}}>
                                    <img src={`${empDocumentUrl}/${list.document_path}`} alt="image" style={{width: '100%', height: '220px' }} className="img-fluid" />
                                </div>
                            </div>
                        </>)}
                    </div>
                    {page == 'verification' ? (<>
                        <div>
                        <button className="badge badge-pill bg-light-info" style={{ border: 'none' }} onClick={() => popupDocument(`${empDocumentUrl}/${list.document_path}`)}>
                            <i class="fa fa-eye" aria-hidden="true"></i>  Review</button>
                        <p class="card-text p-1 m-0 text-muted">
                            {list.approval_status == null ? (<><span className="">Not approved yet</span></>) : (
                                list.approval_status == 1 ? (<><span className="text-success">Approved</span></>) : (<><span className="text-danger">Rejected</span></>)
                            )}</p>
                        <p class="card-text p-0">
                            <button className="badge bg-success m-1" style={{ border: 'none' }} onClick={() => decisionStore(list.id, '1')} >
                                <i class="fa fa-right" aria-hidden="true"></i>  Approve</button>
                            <button className="badge bg-danger" style={{ border: 'none' }} onClick={() => decisionStore(list.id,'0')} >
                                <i class="fa fa-cross" aria-hidden="true"></i>  Reject</button>
                        </p>
                    </div>
                    
                    </>):(<></>)}
                  
                    </>);
            })}
        </Slider>
    </>);
}

export default EmployeeDocumentCard;
